import React from 'react'
import { Link } from 'gatsby'
import Box from '../Box'
import Logo from '../Logo'
import Menu from './Menu'

export default ({
  variant = 'light',
  ...rest
}) => (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='space-between'
    paddingX={{ xs: 0, sm: 1 }}
    {...rest}
  >
    <Link as={Box} to='/'>
      <Logo />
    </Link>

    <Menu variant={variant} />
  </Box>
)
