import React from 'react'
import Box from '../../Box'

export default ({
  alt,
  href,
  ...props
}) => (
  <Box
    component='a'
    display='flex'
    target='_blank'
    rel="noopener noreferrer"
    aria-label={alt}
    alt={alt}
    href={href}
  >
    <Box
      color='grey1'
      fontSize={26 / 16}
      {...props}
    />
  </Box>
)
