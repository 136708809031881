import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql'
import InstagramDark from '../../assets/instagram-dark.svg'
import InstagramLight from '../../assets/instagram-light.svg'
import Box from '../Box'
import Icon from './Icon'

const query = graphql`
  {
    prismic {
      allSettings {
        edges {
          node {
            instagram_url
          }
        }
      }
    }
  }
`

export default ({
  color = 'grey4',
  variant = 'dark',
  ...rest
}) => (
  <StaticQuery
    query={query}
    render={withPreview(({
      prismic: {
        allSettings: {
          edges: [{
            node: {
              instagram_url,
            },
          }],
        },
      },
    }) => (
      <Box
        display='flex'
        justifyContent='center'
      >
        <Icon
          component={variant === 'dark' ? InstagramDark : InstagramLight}
          href={instagram_url}
          alt='Instagram'
          {...rest}
        />
      </Box>
    ), query)}
  />
)
