import _ from 'lodash'

export default ({
  data: {
    prismic: {
      allPosts: {
        edges,
      },
    },
  },
  geo: {
    properties: {
      name,
      ISO_A2,
    },
  },
}) => (
  _.reject(
    _.concat(
      _.filter(edges, {
        node: {
          country: {
            iso_a2: ISO_A2,
          },
        },
      }),
      _.filter(edges, {
        node: {
          state: {
            name,
          },
        },
      }),
    )
  , ({
    node: {
      school_name,
    },
  }) => (
    _.isEmpty(school_name)
  ))
)
