import _ from 'lodash'

export default ({
  data: {
    prismic: {
      allPosts: {
        edges,
      },
    },
  },
}) => (
  _.map(edges, 'node')
)
