import expandStyleShorthands from '@amendable/expand-style-shorthands';
import colorsPolished from '@amendable/colors-polished';
import scale from '@amendable/scale';
import spacingAliases from '@amendable/spacing-aliases';
import breakpointsCss from '@amendable/breakpoints-css';
import inlinePropsCss from '@amendable/inline-props-css';
import colors from './colors';

export default [
  expandStyleShorthands(),
  breakpointsCss(),
  colorsPolished({ colors }),
  spacingAliases(),
  scale({ base: 16, calculation: ({ value, base }) => `${value * base}px` }),
  inlinePropsCss(),
];
