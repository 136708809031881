import React from 'react'
import { Link } from 'gatsby';
import ArrowRight from '../../../assets/arrow-right.svg'
import Box from '../../Box'

export default ({
  to,
  ...rest
}) => (
  <Box
    paddingY={4}
    display='flex'
    justifyContent='flex-end'
  >
    <Box component={Link} to={to}>
      <Box marginLeft={-2} {...rest} />
      <Box>
        <ArrowRight />
      </Box>
    </Box>
  </Box>
)
