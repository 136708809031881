import React from 'react'
import BackgroundImage from 'gatsby-background-image';
import Box from '../Box'

export default ({
  image,
  imageSharp,
  ...rest
}) => {
  if (!imageSharp) {
    return (
      <Box
        background={image ? `url(${image.url})` : ''}
        {...rest}
      />
    )
  }

  return (
    <Box
      component={BackgroundImage}
      fluid={imageSharp?.childImageSharp?.fluid}
      {...rest}
    />
  )
}
