import React from 'react';
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import { Location } from '@reach/router';
import { Helmet } from 'react-helmet';
import url from './lib/url';
import ogImageUrl from './lib/ogImageUrl';

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        ogImage
        twitterSite
        lang
      }
    }

    prismic {
      allSettings {
        edges {
          node {
            og_image
            og_title
            og_description
          }
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={withPreview(({
      site: {
        siteMetadata,
      },
      prismic: {
        allSettings: {
          edges: [{
            node: settingsNode,
          }],
        },
      },
    }) => {
      const {
        title,
        description,
        ogImage,
        twitterSite,
        lang,
      } = _.defaults(_.cloneDeep(props), {
        ogImage: settingsNode.og_image?.url,
        title: settingsNode.og_title,
        description: settingsNode.og_description,
      }, siteMetadata)

      return (
        <Location>
          {({ location }) => (
            <Helmet
              htmlAttributes={{
                lang,
              }}
            >
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />

              <meta property="og:image" content={ogImageUrl(ogImage)} />
              <meta property="og:image:url" content={ogImageUrl(ogImage)} />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="630" />
              <meta property="og:image:type" content="image/png" />
              <meta property="og:url" content={url({ location })} />
              <link rel="canonical" href={url({ location })} />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:site_name" content={title} />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:site" content={twitterSite} />
              <meta property="twitter:title" content={title} />
              <meta property="twitter:description" content={description} />
              <meta property="twitter:image" content={ogImageUrl(ogImage)} />

              <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
              <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
              <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
              <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
              <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
              <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
              <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
              <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
              <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="manifest" href="/manifest.json" />
              <meta name="msapplication-TileColor" content="#ffffff" />
              <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
              <meta name="theme-color" content="#ffffff"/>
            </Helmet>
          )}
        </Location>
      )
    }, query)}
  />
)
