import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql'
import Box from '../../Box'

const query = graphql`
  {
    prismic {
      allSettings {
        edges {
          node {
            email
          }
        }
      }
    }
  }
`

export default () => (
  <Box
    fontFamily='Cabin'
    color='grey1'
    paddingBottom={1}
  >
    <Box>
      <Box>
        VšĮ "Švietimo nuotykiai", į. k. 305421935
      </Box>
      <Box>
        Mingailė Žemaitytė-Meldaikė
      </Box>
      <Box>
        Tautvydas Meldaikis
      </Box>
      <StaticQuery
        query={query}
        render={withPreview(({
          prismic: {
            allSettings: {
              edges: [{
                node: {
                  email,
                },
              }],
            },
          },
        }) => (
          <Box>
            {email}
          </Box>
        ), query)}
      />
    </Box>

    <Box paddingTop={1}>
      © Švietimo nuotykiai
    </Box>
  </Box>
)
