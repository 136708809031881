import _ from 'lodash'

export const areas = [
  {
    slug: 'europe',
    name: 'Europe',
    nameLt: 'Europa',
    geographiesProps: {
      geography: "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json",
    },
    composableMapProps: {
      projection: "geoAzimuthalEqualArea",
      projectionConfig: {
        rotate: [-20.0, -50.0, 0],
        scale: 700
      },
    },
  },
  {
    slug: 'usa',
    name: 'USA',
    nameLt: 'JAV',
    geographiesProps: {
      geography: "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json",
    },
    composableMapProps: {
      projection: "geoAzimuthalEqualArea",
      projectionConfig: {
        rotate: [100.0, -40.0, 0],
        scale: 800
      },
    },
  },
  {
    slug: 'central-america',
    name: 'Central America',
    nameLt: 'Centrinė Amerika',
    geographiesProps: {
      geography: "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json",
    },
    composableMapProps: {
      projection: "geoAzimuthalEqualArea",
      projectionConfig: {
        rotate: [80.0, -10.0, 0],
        scale: 700
      },
    },
  },
]

export default ({
  area,
}) => (
  _.find(areas, { slug: area })
)
