import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import 'moment/locale/lt'
import ArrowCircle from '../../../../assets/arrow-circle.svg'
import Box from '../../../Box'

export default ({
  post: {
    is_video,
    reading_time,
    _meta: {
      uid,
      firstPublicationDate,
    },
  },
}) => (
  <Box
    paddingTop={1}
    paddingRight={2}
  >
    <Box
      component={Link}
      to={`/posts/${uid}`}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box
        fontSize={14 / 16}
        color='hsla(211, 19%, 56%, 1)'
        textTransform='capitalize'
      >
        {moment(firstPublicationDate).format('MMM DD, YYYY')} {reading_time ? `⋅ ${reading_time}` : ''}
      </Box>
      <Box
        component={ArrowCircle}
      />
    </Box>
  </Box>
)
