import React from 'react'
import Box from '../../Box'
import MapWithPosts from '../../MapWithPosts'
import LinkArrow from '../LinkArrow'
import scale from '../Splash/lib/scale'

export default () => (
  <Box
    paddingX={1}
    paddingRight={0}
    flexGrow={1}
    paddingTop={5}
    id='map'
  >
    <Box
      fontSize={scale(24, 46)}
      fontWeight={500}
      color='grey4'
      fontFamily='Noto Nastaliq Urdu'
      wordBreak='break-word'
    >
      Mokyklų žemėlapis
    </Box>
    <MapWithPosts />
    {false && <Box
      paddingRight={1}
    >
      <LinkArrow to='/aplankytos-mokyklos'>
        Pilnas mokyklų sąrašas
      </LinkArrow>
    </Box>}
  </Box>
)
