import React from 'react'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql'
import Box from '../../Box'
import CommonBlock from '../CommonBlock'
import Supporter from './Supporter'

const query = graphql`
  {
    prismic {
      allSupporters {
        edges {
          node {
            name
            logo
            url
            is_main

            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={withPreview(({
      prismic: {
        allSupporters: {
          edges,
        },
      },
    }) => {
      const mainSupporters = _.filter(edges, {
        node: {
          is_main: true,
        },
      })

      const supporters = _.filter(edges, {
        node: {
          is_main: false,
        },
      })

      return (
        <>
          <CommonBlock
            id='main-supporters'
            title='Globėjas'
            backgroundColor='yellow7'
            bubble={false}
          >
            <Box
              display='grid'
              gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
              gridGap='48px'
            >
              {_.map(mainSupporters, ({ node }) => (
                <Supporter key={node._meta.uid} supporter={node} />
              ))}
            </Box>
          </CommonBlock>
          <CommonBlock
            id='supporters'
            title='Partneriai'
            backgroundColor='yellow7'
            bubble={false}
          >
            <Box
              display='grid'
              gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr 1fr' }}
              gridGap='48px'
            >
              {_.map(supporters, ({ node }) => (
                <Supporter key={node._meta.uid} supporter={node} />
              ))}
            </Box>
          </CommonBlock>
        </>
      )
    }, query)}
  />
)
