import React from 'react'
import { Link } from 'gatsby';
import Box from '../../Box'
import SocialMail from '../../Social/Mail'
import SocialYoutube from '../../Social/Youtube'
import SocialInstagram from '../../Social/Instagram'
import SocialFacebook from '../../Social/Facebook'

export default () => (
  <Box
    display='flex'
    paddingY={2}
    alignItems='center'
    justifyContent='flex-end'
  >
    <SocialMail stroke='grey4' marginRight={1} />
    <SocialYoutube stroke='grey4' marginRight={1} />
    <SocialInstagram stroke='grey4' marginRight={1} />
    <SocialFacebook stroke='grey4' marginRight={2} />
    <Box
      display='block'
      component={Link}
      to='/support-us'
      borderRadius={6 / 16}
      paddingX={1}
      paddingY={0.3}
      border='1px solid #374450'
      hoverBackgroundColor='hsla(209, 19%, 26%, 0.1)'
    >
      Prisidėk
    </Box>
  </Box>
)
