import React from 'react'
import _ from 'lodash'
import Box from '../../../Box'
import { areas } from '../Map/lib/mapProps'

export default ({
  area,
  setArea,
}) => (
  <Box
    paddingBottom={2}
    display='flex'
    alignItems='center'
  >
    {_.map(areas, ({
      nameLt,
      slug,
    }) => (
      <Box
        key={slug}
        cursor='pointer'
        onClick={() => setArea(slug)}
        fontWeight={450}
        fontSize={20 / 16}
        color='grey4'
        marginRight={1}
        padding={0.05}
        textAlign='center'
        borderBottom={slug === area ? '4px solid yellow5' : '4px solid transparent'}
      >
        {nameLt}
      </Box>
    ))}
  </Box>
)
