import React from 'react'
import { AmendableProvider } from '@amendable/core'
import CandourNormalize from 'candour-normalize'
import '../../theme/index.css'
import resolvers from '../../theme/resolvers'
import Meta from '../Meta'

export default ({ children }) => (
  <AmendableProvider resolvers={resolvers}>
    <CandourNormalize />
    <Meta />
    {children}
  </AmendableProvider>
)
