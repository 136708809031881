import React, { useState } from 'react'
import Box from '../../Box'
import ActiveZone from './ActiveZone'
import Map from './Map'
import AreaSelect from './AreaSelect'

export default ({
  data,
}) => {
  const [activeZone, setActiveZone] = useState(null)
  const [area, setArea] = useState('europe')

  return (
    <Box
      position='relative'
    >
      <AreaSelect area={area} setArea={setArea} />
      <Map setActiveZone={setActiveZone} area={area} data={data} />
      {activeZone && <ActiveZone zone={activeZone} setActiveZone={setActiveZone} />}
    </Box>
  )
}
