import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'
import { RichText } from 'prismic-reactjs'
import Box from '../../Box'
import Footer from './Footer'

export default ({
  post: {
    title,
    content,
    _meta: {
      uid,
    },
  },
  post,
}) => (
  <Box>
    <Box
      component={Link}
      to={`/posts/${uid}`}
      fontWeight={700}
      fontSize={20 / 16}
      lineHeight={30 / 16}
      color='grey4'
      fontFamily='Noto Nastaliq Urdu'
      hoverTextDecoration='underline'
    >
      {_.truncate(title, { length: 70 })}
    </Box>
    <Box
      paddingTop={1}
      fontSize={18 / 16}
      fontFamily='Cabin'
      color='grey4'
    >
      {_.truncate(RichText.asText(content), { length: 150 })}
    </Box>
    <Footer post={post} />
  </Box>
)
