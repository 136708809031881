export default ({
  post: {
    location,
  },
}) => {
  if (!location) return null
  const {
    longitude,
    latitude,
  } = location

  return [
    longitude,
    latitude,
  ]
}
