import React from 'react'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import Box from '../Box'
import Item from './Item'
import Pages from './Pages'
import CollapsedMenu from './CollapsedMenu'

const query = graphql`
  {
    prismic {
      allPages {
        edges {
          node {
            menu_title
            menu_position
            is_project
            is_service

            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={withPreview(({
      prismic: {
        allPages: {
          edges,
        },
      },
    }) => {
      const sortedPages = _.sortBy(_.map(edges, 'node'), 'menu_position')
      const customPages = _.reject(_.reject(sortedPages, 'is_project'), 'is_service')

      return (
        <Box>
          <Pages pages={[_.first(customPages)]} />
          <CollapsedMenu pages={_.filter(sortedPages, 'is_project')}>
            Projektai
          </CollapsedMenu>
          <CollapsedMenu pages={_.filter(sortedPages, 'is_service')}>
            Paslaugos
          </CollapsedMenu>
          <Item to='/posts'>
            Tekstai
          </Item>
          <Item to='/videos'>
            Filmukai
          </Item>
          <Pages pages={_.drop(customPages)} />
        </Box>
      )
    }, query)}
  />
)
