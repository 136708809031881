import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql'
import Image from 'gatsby-image'
import Box from '../../Box'

const query = graphql`
  query {
    file(relativePath: { eq: "outro.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={withPreview(({
      file: {
        childImageSharp: { fluid },
      },
    }) => (
      <Box
        component={Image}
        fluid={fluid}
        width='100%'
        marginBottom={-1 / 16}
      />
    ), query)}
  />
)
