import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql'
import Box from '../../../Box'
import Form from './Form'

const query = graphql`
  {
    prismic {
      allSettings {
        edges {
          node {
            hero_title
            hero_description
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={withPreview(({
      prismic: {
        allSettings: {
          edges: [{
            node: {
              hero_title,
              hero_description,
            },
          }],
        },
      },
    }) => (
      <Box
        maxWidth={{ xs: 'inherit', md: 35 }}
        paddingTop={{ xs: 2, md: 0 }}
      >
        <Box
          component='h1'
          fontFamily='Noto Nastaliq Urdu'
          fontWeight={700}
          fontSize={{ xs: 32 / 16, md: 50 / 16 }}
          lineHeight={{ xs: 40 / 16, md: 60 / 16 }}
          color='grey4'
          textAlign={{ xs: 'center', md: 'inherit' }}
        >
          {hero_title}
        </Box>
        <Box
          paddingTop={1}
          component='h2'
          fontFamily='Noto Nastaliq Urdu'
          fontSize={{ xs: 18 / 16, md: 26 / 16 }}
          lineHeight={{ xs: 25 / 16, md: 40 / 16 }}
          color='grey4'
          textAlign={{ xs: 'center', md: 'inherit' }}
        >
          {hero_description}
        </Box>

        {false && <Box
          display={{ xs: 'none', md: 'block' }}
        >
          <Form />
        </Box>}
      </Box>
    ), query)}
  />
)
