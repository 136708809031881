import React from 'react'
import Box from '../Box'
import Footer from '../Footer'
import Topbar from '../Topbar'
import Menu from './Menu'
import Social from './Social'

export default ({
  children,
}) => (
  <Box>
    <Box
      backgroundColor='yellow2'
      display='flex'
      flexGrow={1}
      minHeight='100vh'
      paddingBottom={{ xs: 0, lg: 10 }}
      flexDirection={{ xs: 'column', lg: 'row' }}
    >
      <Box
        display={{ xs: 'block', lg: 'none' }}
        padding={1}
      >
        <Topbar />
      </Box>

      <Box
        display={{ xs: 'none', lg: 'flex' }}
        padding={2}
        paddingRight={0}
      >
        <Menu />
      </Box>
      <Box
        paddingX={{ xs: 0, lg: 2 }}
        flexGrow={1}
        overflow='hidden'
      >
        <Box
          display={{ xs: 'none', md: 'block' }}
        >
          <Social />
        </Box>
        {children}
      </Box>
    </Box>
    <Footer />
  </Box>
)
