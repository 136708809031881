import React from 'react'
import Box from '../../Box'
import Heading from './Heading'
import Bubbles from './Bubbles'

export default () => (
  <Box
    flexGrow={1}
    padding={1}
  >
    <Box>
      <Box
        display={{ xs: 'block', md: 'none' }}
        marginTop={-1}
        marginX={-2}
        paddingBottom={2}
      >
        <Bubbles />
      </Box>
      <Box
        display='flex'
        alignItems='center'
      >
        <Box>
          <Heading />
        </Box>
        <Box
          display={{ xs: 'none', md: 'block' }}
          width={35}
          marginRight={-5}
        >
          <Bubbles />
        </Box>
      </Box>
    </Box>
  </Box>
)
