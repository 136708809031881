import React from 'react'
import Layout from '../Layout'
import Box from '../Box'
import FullWidthLayout from '../FullWidthLayout'
import NewPosts from '../NewPosts'
import Subscribe from '../Subscribe'
import Support from '../Support'
import Splash from './Splash'
import Schools from './Schools'
import Supporters from './Supporters'

export default () => (
  <Layout>
    <FullWidthLayout overflow='hidden'>
      <Splash />
      <Box paddingX={1}>
        <NewPosts />
      </Box>
      <Schools />
      <Support />
      <Supporters />
      <Subscribe />
    </FullWidthLayout>
  </Layout>
)
