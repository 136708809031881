import React from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';
import ArrowRight from '../../../../assets/arrow-right.svg'
import {
  FiX,
} from 'react-icons/fi'
import Box from '../../../Box'
import postsIn from '../Map/lib/postsIn'
import name from './lib/name'

export default ({
  zone: {
    data,
    geo,
  },
  zone,
  setActiveZone,
}) => {
  const posts = postsIn({ data, geo })
  const firstPost = _.first(posts)
  const {
    node: {
      imageSharp: {
        childImageSharp: {
          fluid,
        },
      },
    },
  } = firstPost

  return (
    <Box
      position='fixed'
      overflow='scroll'
      backgroundColor='grey9-transparentize30'
      top={0}
      bottom={0}
      left={0}
      right={0}
      padding={2}
      paddingTop={1}
      zIndex={1110}
    >
      <Box
        display='flex'
        paddingBottom={1}
        justifyContent='flex-end'
      >
        <Box
          component={FiX}
          fontSize={30 / 16}
          onClick={() => setActiveZone(null)}
          cursor='pointer'
          stroke='grey1'
          zIndex={1112}
        />
      </Box>
      <Box
        minHeight='90vh'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        position='relative'
      >
        <Box
          position='absolute'
          top={-10}
          left={{ xs: 0, lg: 10 }}
          component={BackgroundImage}
          fluid={fluid}
          width={15}
          height={20}
          marginLeft={{ xs: 0, lg: 10 }}
          marginTop={{ xs: 1, lg: 0 }}
          zIndex={1111}
          borderRadius={4 / 18}
          overflow='hidden'
        />
        <Box
          zIndex={1113}
          position='absolute'
          textAlign='center'
        >
          <Box
            fontSize={70 / 16}
            color='grey1'
            fontFamily='Noto Nastaliq Urdu'
            fontWeight={500}
          >
            {name({ firstPost })}
          </Box>

          <Box paddingTop={2}>
            {_.map(posts, ({
              node: {
                school_name,
                _meta: {
                  uid,
                },
              },
            }) => (
              <Box key={uid} paddingBottom={1}>
                <Box
                  color='grey1'
                  fontSize={24 / 16}
                  fontWeight={450}
                >
                  {school_name}
                </Box>
                <Box
                  display='flex'
                  justifyContent='flex-end'
                >
                  <Box
                    paddingTop={0.5}
                    component={Link}
                    to={`/posts/${uid}`}
                  >
                    <Box marginLeft={-1} color='grey1'>
                      Skaityti
                    </Box>
                    <Box>
                      <Box component={ArrowRight} fill='grey1' width={5} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
