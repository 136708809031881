export default ({
  firstPost: {
    node: {
      country,
      state,
    },
  },
}) => (
  country?.name || state?.name
)
