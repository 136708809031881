import React, { memo } from 'react'
import _ from 'lodash'
import {
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";
import colors from '../../../../theme/colors'
import Box from '../../../Box'
import Marker from './Marker'
import isVisited from './lib/isVisited'
import posts from './lib/posts'
import mapProps from './lib/mapProps'

export default memo(({
  setActiveZone,
  area,
  data,
}) => (
  <ComposableMap
    height={400}
    data-tip=""
    {...mapProps({
      area,
    }).composableMapProps}
  >
    <Geographies {...mapProps({ area }).geographiesProps}>
      {({ geographies, ...rest }) => (
        _.map(geographies, (geo) => (
          <Box
            component={Geography}
            key={geo.rsmKey}
            geography={geo}
            onClick={() => isVisited({ data, geo }) && setActiveZone({ geo, data })}
            fill={isVisited({ data, geo }) ? 'grey1' : 'grey5'}
            stroke={isVisited({ data, geo }) ? 'grey9' : 'grey1'}
            strokeWidth={0.5}
            style={{
              hover: {
                fill: colors.yellow6,
              },
            }}
          />
        ))
      )}
    </Geographies>
    {_.map(posts({ data }), (post) => (
      <Marker
        key={post._meta.uid}
        post={post}
      />
    ))}
  </ComposableMap>
))
