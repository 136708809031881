import React from 'react'
import Box from '../../../Box'

export default ({
  supporter: {
    logo,
    url,
    name,
  },
}) => {
  if (!logo) return null

  return (
    <Box
      component='a'
      display='flex'
      justifyContent='center'
      href={url}
      target='_blank'
      rel="noopener noreferrer"
      aria-label={name}
      alt={name}
      background={`url(${logo.url})`}
      backgroundSize='contain'
      backgroundRepeat='no-repeat'
      backgroundPosition='center'
      flexGrow={1}
      minWidth={5}
      minHeight={10}
    />
  )
}
