import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image'
import { withPreview } from 'gatsby-source-prismic-graphql'
import Box from '../../../Box'

const query = graphql`
  {
    prismic {
      allSettings {
        edges {
          node {
            hero_big_image
            hero_big_imageSharp {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
            hero_small_image
            hero_small_imageSharp {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`


export default () => (
  <StaticQuery
    query={query}
    render={withPreview(({
      prismic: {
        allSettings: {
          edges: [{
            node: {
              hero_big_imageSharp: {
                childImageSharp: { fluid: bigFluid },
              },
              hero_small_imageSharp: {
                childImageSharp: { fluid: smallFluid },
              },
            },
          }],
        },
      },
    }) => (
      <Box
        position='relative'
        height={26}
        display='flex'
        justifyContent='center'
      >
        <Box
          position='absolute'
          marginTop={3.5}
          marginLeft={2}
          width={20}
          height={20}
          backgroundColor='#DECFBA'
          borderRadius={999999}
        />
        <Box
          position='absolute'
          marginTop={4}
        >
          <Box
            component={Image}
            fluid={bigFluid}
            width={20}
            height={20}
            borderRadius={999999}
          />
        </Box>
        <Box
          position='absolute'
          marginLeft={-8}
          marginTop={18}
        >
          <Box
            component={Image}
            fluid={smallFluid}
            width={8}
            height={8}
            borderRadius={999999}
          />
        </Box>
        <Box
          position='absolute'
          marginLeft={-8}
          marginTop={3}
          width={10}
          height={10}
          backgroundColor='#F3A361'
          borderRadius={999999}
          opacity={0.14}
        />
      </Box>
    ), query)}
  />
)
