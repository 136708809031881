import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql'
import YoutubeDark from '../../assets/youtube-dark.svg'
import YoutubeLight from '../../assets/youtube-light.svg'
import Box from '../Box'
import Icon from './Icon'

const query = graphql`
  {
    prismic {
      allSettings {
        edges {
          node {
            youtube_url
          }
        }
      }
    }
  }
`

export default ({
  color = 'grey4',
  variant = 'dark',
  ...rest
}) => (
  <StaticQuery
    query={query}
    render={withPreview(({
      prismic: {
        allSettings: {
          edges: [{
            node: {
              youtube_url,
            },
          }],
        },
      },
    }) => (
      <Box
        display='flex'
        justifyContent='center'
      >
        <Icon
          component={variant === 'dark' ? YoutubeDark : YoutubeLight}
          href={youtube_url}
          alt='Youtube'
          {...rest}
        />
      </Box>
    ), query)}
  />
)
