import React from 'react'
import Box from '../../Box'
import SocialMail from '../../Social/Mail'
import SocialYoutube from '../../Social/Youtube'
import SocialInstagram from '../../Social/Instagram'
import SocialFacebook from '../../Social/Facebook'

export default () => (
  <Box
    display='flex'
    justifyContent={{ xs: 'center', md: 'flex-start' }}
    alignItems='flex-start'
  >
    <Box
      display='grid'
      gridTemplateColumns='1fr 1fr 1fr 1fr'
      gridGap='32px'
      alignItems='center'
    >
      <SocialMail variant='light' />
      <SocialYoutube variant='light' />
      <SocialInstagram variant='light' />
      <SocialFacebook variant='light' />
    </Box>
  </Box>
)
