import React from 'react'
import { Link } from 'gatsby'
import Video from '../../assets/video.svg'
import Box from '../Box'
import Background from '../Background'
import Content from './Content'

export default ({
  post: {
    title,
    content,
    is_video: isVideo,
    image,
    imageSharp,
    _meta: {
      uid,
    },
  },
  post,
}) => (
  <Box
    display='flex'
    flexDirection='column'
    flexShrink={1}
  >
    <Box
      component={Link}
      to={`/posts/${uid}`}
      position='relative'
      height={15}
    >
      <Box
        height={13}
        position='absolute'
        right={0}
        left={2}
        bottom={0}
        top={2}
        backgroundColor='yellow7'
        borderRadius={4 / 16}
        zIndex={1}
      />
      <Background
        zIndex={2}
        image={image}
        imageSharp={imageSharp}
        height={13}
        width='calc(100% - 32px)'
        backgroundRepeat='no-repeat'
        backgroundSize='cover'
        backgroundPosition='center'
        borderRadius={4 / 16}
        overflow='hidden'
        position='relative'
      />
      {isVideo && (
        <Box
          position='absolute'
          bottom={1}
          right={-1}
          height={3}
          width={3}
          component={Video}
          zIndex={3}
        />
      )}
    </Box>
    <Box paddingTop={2}>
      <Content post={post} />
    </Box>
  </Box>
)
