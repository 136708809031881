import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql'
import MailDark from '../../assets/mail-dark.svg'
import MailLight from '../../assets/mail-light.svg'
import Box from '../Box'
import Icon from './Icon'

const query = graphql`
  {
    prismic {
      allSettings {
        edges {
          node {
            email
          }
        }
      }
    }
  }
`

export default ({
  color = 'grey4',
  variant = 'dark',
  ...rest
}) => (
  <StaticQuery
    query={query}
    render={withPreview(({
      prismic: {
        allSettings: {
          edges: [{
            node: {
              email,
            },
          }],
        },
      },
    }) => (
      <Box
        display='flex'
        justifyContent='center'
      >
        <Icon
          fontSize={1.5}
          component={variant === 'dark' ? MailDark : MailLight}
          href={`mailto:${email}`}
          alt='Email'
          {...rest}
        />
      </Box>
    ), query)}
  />
)
