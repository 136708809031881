import React from 'react'
import { Link } from 'gatsby'
import { useMatch } from "@reach/router"
import { useHover } from 'react-use'
import Box from '../../Box'

export default ({
  children,
  to,
  ...rest
}) => {
  const isActive = to && useMatch(to)

  const element = (hovered) => (
    <Box
      component={to ? Link : undefined}
      to={to}
      color='grey4'
      paddingY={0.25}
      display='flex'
      position='relative'
      alignItems='center'
      paddingRight={3.5}
      cursor='pointer'
      {...rest}
    >
      <Box width={2}
        height={2}
        backgroundColor={isActive ? 'grey1' : 'none'}
        borderRadius={9999}
        marginRight={0.5}
      >
        <Box width={1.5} paddingLeft={1.5} paddingTop={0.5}>
          <Box display={(isActive || hovered) ? 'flex' : 'none'} fontSize={6 / 16} lineHeight={14 / 16}>●</Box>
        </Box>
      </Box>
      <Box
        display='flex'
      >
        <Box
          fontSize={14 / 16}
          fontFamily='Cabin'
          fontWeight={600}
          textTransform='uppercase'
          color='grey4'
        >
          {children}
        </Box>
      </Box>
    </Box>
  )

  const [hoverable] = useHover(element)
  return hoverable
}
