import React, { useState } from 'react'
import { navigate } from 'gatsby'
import {
  Marker
} from "react-simple-maps";
import Box from '../../../../Box'
import coordinates from './lib/coordinates'

export default ({
  post,
  post: {
    _meta: {
      uid,
    },
  },
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const coords = coordinates({ post })

  if (!coords) return null

  return (
    <Box
      component={Marker}
      coordinates={coords}
      onClick={() => navigate(`/posts/${uid}`)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cursor='pointer'
    >
      <Box
        component='circle'
        r={7}
        fill={isHovered ? 'yellow5' : 'yellow6'}
      />

      <Box
        component='circle'
        r={7}
        fill='transparent'
        cy={-1}
        cx={0.5}
        stroke='grey9'
        strokeWidth={0.5}
      />
    </Box>
  )
}
