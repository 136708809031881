import _ from 'lodash'
import postsIn from './postsIn'

export default ({
  data,
  geo,
}) => (
  !_.isEmpty(postsIn({
    data,
    geo,
  }))
)
