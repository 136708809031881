import React, { useState } from 'react'
import Box from '../../Box'
import Menu from '../../Menu'

export default ({
  variant,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box position='relative'>
      <Box
        color={variant === 'light' ? 'grey4' : 'grey1'}
        cursor='pointer'
        onClick={() => setIsOpen(!isOpen)}
        textTransform='uppercase'
        fontSize={14 / 16}
      >
        Meniu
      </Box>
      {isOpen && (
        <Box
          position='fixed'
          top={0}
          right={0}
          bottom={0}
          backgroundColor='yellow4-transparentize5'
          minWidth={20}
          padding={1}
          zIndex={999999}
        >
          <Box
            display='flex'
            justifyContent='flex-end'
            paddingTop={2.9}
            paddingRight={1}
            paddingBottom={3}
          >
            <Box
              backgroundColor={variant === 'light' ? 'grey4' : 'grey1'}
              height={1}
              width={1}
              borderRadius={999}
              cursor='pointer'
              onClick={() => setIsOpen(!isOpen)}
            />
          </Box>
          <Menu />
        </Box>
      )}
    </Box>
  )
}
