import React from 'react'
import _ from 'lodash'
import Item from '../Item'

export default ({
  pages,
}) => (
  <>
    {_.map(pages, ({
      menu_title: menuTitle,
      _meta: {
        uid,
      },
    }) => (
      <Item key={uid} to={`/${uid}`}>
        {menuTitle}
      </Item>
    ))}
  </>
)
