import React from 'react'
import { Link } from 'gatsby';
import Box from '../../Box'
import LinkArrow from '../LinkArrow'
import scale from '../Splash/lib/scale'

export default ({
  id,
  title,
  to,
  linkTitle,
  description,
  children,
  backgroundColor,
  bubble = true,
}) => (
  <Box
    id={id}
    marginY={{ xs: 2, lg: 5 }}
    paddingTop={{ xs: 0, lg: 5 }}
    paddingX={1}
  >
    <Box
      component={Link}
      to={to}
      fontSize={scale(24, 46)}
      lineHeight={scale(27, 50)}
      display='block'
      paddingBottom={1}
      fontWeight={500}
      color='grey4'
      fontFamily='Noto Nastaliq Urdu'
      wordBreak='break-word'
    >
      {title}
    </Box>
    <Box
      display={{ xs: 'block', lg: 'flex' }}
      alignItems='center'
    >
      <Box
        paddingRight={{ xs: 0, lg: 4 }}
        display='flex'
        flexGrow={1}
        flexBasis={{ xs: 'auto', lg: 2 }}
        position='relative'
        paddingBottom={{ xs: 1, lg: 0 }}
      >
        <Box
          display={{ xs: 'none', lg: bubble ? 'block' : 'none' }}
          position='absolute'
          left={-2}
          bottom={-2}
          zIndex={1}
          borderRadius={999}
          backgroundColor={backgroundColor}
          width={15}
          height={15}
        />
        <Box zIndex={2} flexGrow={1}>
          {children}
        </Box>
      </Box>
      {description && <Box
        flexGrow={1}
        flexBasis={{ xs: 'auto', lg: 1 }}
      >
        <Box
          color='grey4'
          fontSize={22 / 16}
        >
          {description}
        </Box>
      </Box>}
    </Box>
    {to && <LinkArrow to={to}>
      {linkTitle}
    </LinkArrow>}
  </Box>
)
