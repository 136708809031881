import React from 'react'
import Box from '../Box'
import Info from './Info'
import Social from './Social'
import Thanks from './Thanks'

export default () => (
  <Box>
    <Thanks />
    <Box
      backgroundColor='grey4'
      padding={{ xs: 1, md: 4 }}
      paddingY={{ xs: 1, md: 2 }}
      zIndex={9999}
      position='absolute'
      width='100%'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Info />
        <Social />
      </Box>
    </Box>
  </Box>
)
