import React, { useState } from 'react'
import _ from 'lodash'
import Item from '../Item'

export default ({
  pages,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Item onClick={() => setIsOpen(prev => !prev)}>
        {children}
      </Item>
      {isOpen && (
        <>
          {_.map(pages, ({
            menu_title: menuTitle,
            _meta: {
              uid,
            },
          }) => (
            <Item key={uid} to={`/${uid}`} marginLeft={1}>
              {menuTitle}
            </Item>
          ))}
        </>
      )}
    </>
  )
}
