import React from 'react'
import { Link } from 'gatsby'
import Box from '../../Box'
import Logo from '../../Logo'
import Menu from '../../Menu'

export default () => (
  <Box
    position='relative'
    width={16}
    flexShrink={0}
  >
    <Box
      position='fixed'
    >
      <Box paddingLeft={1}>
        <Link to='/'>
          <Logo />
        </Link>
      </Box>

      <Box paddingTop='30vh'>
        <Menu />
      </Box>
    </Box>
  </Box>
)
