export default {
  grey1: '#ffffff',
  grey2: '#FCFBF3',
  grey4: '#36424F',
  grey5: '#17282F',
  grey9: '#000000',
  yellow2: '#F2F1EF',
  yellow4darken: 'hsla(40, 10%, 54%, 1)',
  yellow4: '#F0DFB3',
  yellow5: '#F1A056',
  yellow6: '#E6A86C',
  yellow7: '#DFCFB9',
  yellow8: '#EDDB66',
}
