import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import Inner from './Inner'

const query = graphql`
  {
    prismic {
      allPosts {
        edges {
          node {
            title
            content
            is_video
            image
            imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            location
            school_name

            author {
              ... on PRISMIC_Person {
                name
                avatar
              }
            }

            country {
              ... on PRISMIC_Country {
                iso_a2
                name

                _meta {
                  uid
                }
              }
            }

            state {
              ... on PRISMIC_State {
                name

                _meta {
                  uid
                }
              }
            }

            _meta {
              uid
              firstPublicationDate
            }
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={withPreview((data) => (
      <Inner data={data} />
    ), query)}
  />
)
